<!-- 对象通用列表页 -->
<template>
  <div class="search_box">
    <div
      v-if="exactSearchFlag !== '1'"
      :class="
        this.$route.path.indexOf('multi-screen') === -1
          ? 'tableViewWidth'
          : 'multiScreenViewWidth'
      "
    >
      <el-input
        :placeholder="placeholder"
        v-model="search"
        @change="changeKeyWord"
      >
      </el-input>
      <svg
        :class="
          this.$route.path.indexOf('multi-screen') !== -1
            ? 'icon searchBtn left-20'
            : 'icon searchBtn'
        "
        aria-hidden="true"
        @click="changeKeyWord"
      >
        <use href="#icon-search"></use>
      </svg>
    </div>
    <div v-if="exactSearchFlag === '1'">
      <div
        class="editableCellBox"
        v-for="field in exactSearchFieldList"
        :key="field.fieldId"
        :style="{ width: width }"
      >
        <EditableCell
          class="editableCell"
          ref="editableCell"
          :field-id="field.fieldId"
          :placeholder="
            $t('label.searchs.labelname', { fieldlabelName: field.labelName })
          "
          input-type="input"
          @searchEnterBtn="changeKeyWord"
        />
        <svg class="icon searchBtn" aria-hidden="true" @click="changeKeyWord">
          <use href="#icon-sousuo"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description 搜索条件组件,一般用于表格上方搜索条件
 * 功能：
 * 1、展示搜索框
 * 逻辑：
 * 1、根据父组件传递进来的参数，展示搜索框，支持单字段搜索时，只展示一个搜索框
 */
import EditableCell from "@/components/TablePanel/EditableCell";

export default {
  props: {
    /**
     * 每个搜索框宽度
     */
    width: {
      type: String,
      default: "100%",
    },
    /**
     * 单字段搜素时，搜索框占位符
     */
    placeholder: {
      type: String,
    },
    /**
     * 是否支持单字段搜索标识
     */
    exactSearchFlag: {
      type: String,
      default: "",
    },
    /**
     * 单字段搜索字段集合
     */
    exactSearchFieldList: {
      type: Array,
      default: () => [],
    },
  },
  components: { EditableCell },
  data() {
    return {
      search: "", // 单字段搜索时搜素内容值
      searchKeyWord: "", // 多字段搜索时搜索内容的值
    };
  },
  methods: {
    /**
     * 清空搜索条件
     */
    clearSearchKeyWord() {
      this.searchKeyWord = "";
      this.$refs.editableCell.forEach((editableCell) => {
        editableCell.editValue = "";
      });
    },
    /**
     * 搜索内容改变时，将内容传至父组件进行搜索
     */
    changeKeyWord(val) {
      setTimeout(() => {
        this.searchKeyWord = "";
        // 单字段搜索 看板视图不支持
        if (this.exactSearchFlag === "1") {
          this.$refs.editableCell.forEach((editableCell) => {
            if (editableCell.editValue !== "") {
              this.searchKeyWord += `${editableCell.fieldId}!_!${editableCell.editValue};_;`;
            }
          });
          this.$emit("changeKeyWord", this.searchKeyWord);
        } else {
          this.$emit("changeKeyWord", this.search);
        }
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableViewWidth {
  width: 300px;
  display: flex;
}

.multiScreenViewWidth {
  width: 368px;
  margin-left: 10px;
  display: flex;
}

.search_box {
  position: relative;
}
::v-deep .el-input__inner {
  height: 30px;
  padding-left: 32px;
  border: 1px solid #dedcda;
  font-size: 12px;
}

.searchBtn {
  width: 16px;
  color: #888888;
  position: absolute;
  top: 7px;
  left: 10px;
  margin: 0 0px;
  cursor: pointer;
}

.left-20 {
  left: 20px;
}

.editableCellBox {
  float: left;
  margin-left: 10px;
  position: relative;
}
</style>
